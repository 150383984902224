import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import UnstyledImg from "gatsby-image"
import Page from "../../components/page"
import Wrap from "../../components/wrap"
import Section from "../../components/section"
import bgBasic from "../../images/bg-course-dj-basic-min.jpg"
import bgAdvanced from "../../images/bg-course-dj-advanced-min.jpg"
import Plans from "../../components/content/home/plans"
const PageDjCourse = ({ data }) => {
  return (
    <PageWrap>
      <Page title="DJコース">
        <Wrap>
          <Banner fluid={data.imgBanner.childImageSharp.fluid} />
          <h2>スキルに合わせて選べる２つのコース</h2>
          <TOC>
            <div className="course course-basic">
              <div className="hero">
                <div className="photo">
                  <UnstyledImg
                    fluid={data.imgBasicCircle.childImageSharp.fluid}
                  />
                </div>
                <div className="tagline">
                  <span>未経験でも楽しく</span>
                  <span>基礎を身につける！</span>
                </div>
              </div>
              <h4 className="name">基礎コース</h4>
              <p className="description">
                機材など一切持っていなくても基本や実践的な指導が受けられ、基本テクニックをマスターできる基礎コースです。
              </p>
              <div className="link">
                <a href="">基礎コースの詳細へ</a>
              </div>
            </div>

            <div className="course course-advanced">
              <div className="hero">
                <div className="photo">
                  <UnstyledImg
                    fluid={data.imgAdvancedCircle.childImageSharp.fluid}
                  />
                </div>
                <div className="tagline">
                  <span>さらなる成長を。</span>
                  <span>高度な技術を習得！</span>
                </div>
              </div>
              <h4 className="name">アドバンスコース</h4>
              <p className="description">
                当アカデミーの基礎コースを修了した方、またはすでにDJの基礎レベルがある方を対象にした、更に高度な技術習得が可能なコースです。
              </p>
              <div className="link">
                <a href="">アドバンスコースの詳細へ</a>
              </div>
            </div>
          </TOC>
        </Wrap>
        <Section>
          <SectionHeader className="basic">
            <h2>基礎コース</h2>
            <p className="lead">
              機材など一切持っていなくても基本や実践的な指導が受けられ、学んだことをプレイする場が100%あるのがこのアカデミーです。基本テクニックをマスターできる基礎コース（48時間のレッスン）でDJとしての基礎を身につけた後、希望者はその他のコースを受講できます。
            </p>
            <p className="notice">
              ※スケジュールの変更・キャンセルは
              <br />
              ご予約の3日前までは無料でご対応させて頂きます。
            </p>
          </SectionHeader>
          <Wrap>
            <h3>基礎コース受講の流れ</h3>
            <Flow>
              <div className="step">
                <h4>
                  STEP 01】
                  <br />
                  楽曲の入手方法
                </h4>
                <p>
                  ダンスミュージック専用のダウンロードサイトなどを使って楽曲を入手します。
                </p>
              </div>
              <div className="step">
                <h4>
                  STEP 02】
                  <br />
                  機材に馴れる
                </h4>
                <p>
                  CUEポイントの作り方（頭出しの仕方）BPMの説明、フェードイン・フェードアウト、カットイン・カットアウトなど初歩的な技術を使って機材に馴れてもらいます
                </p>
              </div>
              <div className="step">
                <h4>
                  STEP 03】
                  <br />
                  課題曲で繋ぎを学ぶ
                </h4>
                <p>
                  課題曲を使ってキック合わせとピッチ合わせを正確に出来るようにしMIX技術の基礎を学びます。
                </p>
              </div>
              <div className="step">
                <h4>
                  STEP 04】
                  <br />
                  曲の構成について学ぶ
                </h4>
                <p>
                  ダンスミュージックの音楽構成を学び、MIXポイントを正確に捉え、自分の狙った繋ぎを完璧に出来るように練習します。
                </p>
              </div>
              <div className="step">
                <h4>
                  STEP 05】
                  <br />
                  30分セットの構築
                </h4>
                <p>
                  シチュエーション事に3つのセットを作成し、それぞれのジャンルのMIX技術を習得します。
                </p>
              </div>
              <div className="step">
                <h4>
                  STEP 06】
                  <br />
                  60セットの構築
                </h4>
                <p>
                  ドラマ性を重視し、3つのジャンルを使い60分のセットの構築を行います。
                </p>
              </div>
              <div className="step">
                <h4>
                  STEP 07】
                  <br />
                  バック・トゥ・バック講習
                </h4>
                <p>
                  講師と途切れることなく交互に狙ったジャンルや選曲をその場で瞬時に考えて繋ぐ講習です。実戦を意識した練習となります。
                </p>
              </div>
            </Flow>
            <PricingBasic>
              <h3>基礎コースの料金について</h3>
              <Plans showAdvanced={false} />
              <InfoTableBasic>
                <div className="tuition">
                  <h4>入会金</h4>
                  <p>全コース一律：10,000円</p>
                </div>
                <div className="changes">
                  <h4>スケジュールの変更・キャンセル</h4>
                  <ul>
                    <li>前日キャンセルの場合はレッスン代の半額</li>
                    <li>
                      当日キャンセルの場合はレッスン代の全額がかかります。
                    </li>
                    <li>前日キャンセルは22時までとなっております。</li>
                  </ul>
                </div>
                <div className="rentals">
                  <h4>レンタルについて</h4>
                  <table cellSpacing="0" cellPadding="0">
                    <tbody>
                      <tr>
                        <th>通常レンタルの場合</th>
                        <td>
                          60分3,000円(1室5名まで)で練習用
                          <br />
                          として最新機材を使用出来ます。
                        </td>
                      </tr>
                      <tr>
                        <th>定額レンタルの場合</th>
                        <td>1ヶ月1万円（1日2Hレンタルまで）</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="freelesson">
                  <h4>無料体験</h4>
                  <ul>
                    <li>入学前の無料体験レッスンをご用意しております。</li>
                    <li>
                      実際にレッスンで使われる機材を触りながら、当スクールの雰囲気を感じていただけます。
                    </li>
                    <li>
                      講師と相談の上、ご自身に合ったカリキュラムやコースをご提案させていただくことも可能です。
                    </li>
                  </ul>
                  <p>
                    まずは無料体験レッスンで
                    <br />
                    DJの楽しさを感じてみて下さい！
                  </p>
                  <Link to="/lesson">無料体験を受けてみる 〉</Link>
                </div>
              </InfoTableBasic>
            </PricingBasic>
          </Wrap>
        </Section>
        <Section>
          <SectionHeader className="advanced">
            <h2>アドバンスコース</h2>
            <p className="lead">
              当アカデミーの基礎コースを修了した方、またはすでにDJの基礎レベルがある方を対象にした、更に高度な技術習得が可能なコースがアドバンスコースとなります。現役プロDJから学ぶレッスンや実際のCLUBを使ったレッスンなど、より高度な技術習得を目的とした授業になります。
            </p>
            <p className="notice">
              ※スケジュールの変更・キャンセルは
              <br />
              ご予約の3日前までは無料でご対応させて頂きます。
            </p>
          </SectionHeader>
          <Wrap>
            <h3>アドバンスコースの料金について</h3>
            <AdvancedLessonPrice>
              <table>
                <tbody>
                  <tr>
                    <th>１回 / 55分</th>
                    <td>6000円（税別）</td>
                  </tr>
                </tbody>
              </table>
            </AdvancedLessonPrice>
            <InfoTableAdvanced>
              <div className="tuition">
                <div className="tuition">
                  <h4>入会金</h4>
                  <p>全コース一律：10,000円</p>
                </div>
              </div>
              <div className="changes">
                <div className="changes">
                  <h4>スケジュールの変更・キャンセル</h4>
                  <ul>
                    <li>前日キャンセルの場合はレッスン代の半額</li>
                    <li>
                      当日キャンセルの場合はレッスン代の全額がかかります。
                    </li>
                    <li>前日キャンセルは22時までとなっております。</li>
                  </ul>
                </div>
              </div>
              <div className="rentals">
                <div className="rentals">
                  <h4>レンタルについて</h4>
                  <table cellSpacing="0" cellPadding="0">
                    <tbody>
                      <tr>
                        <th>通常レンタルの場合</th>
                        <td>
                          60分3,000円(1室5名まで)で練習用
                          <br />
                          として最新機材を使用出来ます。
                        </td>
                      </tr>
                      <tr>
                        <th>定額レンタルの場合</th>
                        <td>1ヶ月1万円（1日2Hレンタルまで）</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </InfoTableAdvanced>
          </Wrap>
        </Section>
      </Page>
    </PageWrap>
  )
}

export default PageDjCourse

export const pageQuery = graphql`
  query {
    imgBanner: file(relativePath: { eq: "course-banner-dj.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgBasicCircle: file(relativePath: { eq: "course-dj-basic-circle.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgAdvancedCircle: file(
      relativePath: { eq: "course-dj-advanced-circle.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const PageWrap = styled.div`
  h2 {
    border: 0;
    text-align: center;
  }

  h3 {
    border-left: 0.25em solid #222;
    padding: 0 0 0 0.5em;
    font-size: 1.125rem;
    font-weight: 700;
  }
`

const Banner = styled(UnstyledImg)`
  margin: 2rem auto;
`

const TOC = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  margin: 2rem auto 4rem;

  @media (max-width: 580px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 2rem;
  }

  .course {
    max-width: 450px;
    justify-self: center;

    --color: ${({ theme }) => theme.yellow};
  }

  .hero {
    position: relative;
    display: grid;
    grid-template-columns: 50px 2fr 4fr 50px;

    @media (max-width: 810px) {
      grid-template-columns: 16px 2fr 4fr 16px;
    }
  }

  .tagline {
    position: relative;
    z-index: 2;
    grid-column: 2 / -1;
    grid-row: 1 / -1;
    justify-self: left;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 1.125rem;

    @media (max-width: 660px) {
      font-size: 1rem;
    }

    @media (max-width: 580px) {
      font-size: 1.25rem;
    }

    @media (max-width: 374px) {
      font-size: 1rem;
    }

    span {
      display: inline-block;
      padding: 0.25em 0.5em;
      background: repeating-linear-gradient(
        135deg,
        transparent 0,
        transparent 2px,
        rgba(0, 0, 0, 0.1) 3px,
        rgba(0, 0, 0, 0.1) 5px,
        transparent 6px
      );
      background-color: var(--color);

      &:not(:nth-child(1)) {
        margin-top: 0.5rem;
      }
    }
  }

  .photo {
    position: relative;
    z-index: 1;
    grid-column: 3 / 4;
    grid-row: 1 / -1;
  }

  .name {
    padding: 0 0 0.5rem;
    border-bottom: 1px solid #222;
    text-align: center;
  }

  .description {
    font-size: 0.875rem;
  }

  .link {
    text-align: center;
    font-size: 0.875rem;

    a {
      color: #428cd1;
    }
  }

  .course-advanced {
    --color: ${({ theme }) => theme.red};

    .tagline {
      span {
        background-color: ${({ theme }) => theme.red};
        color: white;
      }
    }
  }
`

const SectionHeader = styled.header`
  margin: 3rem auto;
  padding: 2rem 0;

  &.basic {
    background: url(${bgBasic}) no-repeat center/cover;
  }
  &.advanced {
    background: url(${bgAdvanced}) no-repeat center/cover;
  }

  h2 {
    margin: 0 auto 4rem;
  }

  .lead {
    width: calc(100% - 1rem);
    max-width: 1000px;
    margin: 1rem auto;
  }

  .notice {
    width: calc(100% - 3rem);
    max-width: 460px;
    margin: 3rem auto 0;
    padding: 0.5em 1em;
    background: white;
    font-size: 0.875rem;
    text-align: center;
  }
`

const Flow = styled.div`
  .step {
    position: relative;
    display: grid;
    grid-template-columns: 200px 1fr;
    margin: 2rem 0;
    background: #f2f2f2;

    @media (max-width: 500px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
    }

    &:not(:last-child) {
      &::before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 0;
        height: 0;
        border-top: 1rem solid #f2f2f2;
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
      }
    }
  }

  h4 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 1.5em 0;
    background: black;
    color: white;
    text-align: center;
    font-size: 1rem;
  }

  p {
    margin: 0;
    padding: 1em 1em;
    align-self: center;
  }
`

const PricingBasic = styled.div``

const InfoTableBasic = styled.div`
  display: grid;
  grid-template:
    "tuition freelesson" auto
    "changes freelesson" auto
    "rentals freelesson" auto / 1fr 1fr;
  grid-gap: 1rem;
  margin: 2rem auto;

  @media (max-width: 600px) {
    grid-template:
      "tuition" auto
      "changes" auto
      "rentals" auto
      "freelesson" auto / 1fr;
  }

  > div {
    background: #f2f2f2;
    border: 1px solid #222;
  }

  .tuition {
    grid-area: tuition;
    text-align: center;

    p {
      margin: 1rem;
    }
  }

  .changes {
    grid-area: changes;

    ul {
      margin: 1rem;
      padding: 0 0 0 1rem;
      font-size: 0.875rem;
    }
  }

  .rentals {
    grid-area: rentals;
    border: 0;
    background: none;

    h4 {
      margin: 0;
    }

    table {
      width: 100%;
      margin: 0;
    }

    th,
    td {
      border: 1px solid #222;
    }

    th {
      padding: 1rem;
      background: #d3d3d3;
    }

    td {
      padding: 1rem;
      background: #f2f2f2;
    }
  }

  .freelesson {
    grid-area: freelesson;

    ul {
      margin: 1rem;
      padding: 0 0 0 1rem;
    }

    li {
      margin: 0 0 1rem;

      &:last-child {
        margin: 0;
      }
    }

    p {
      margin: 2rem 0 1rem;
      font-size: 1.125rem;
      font-weight: 700;
      text-align: center;
    }

    a {
      display: block;
      width: 280px;
      margin: 1rem auto;
      padding: 0.5em;
      border-radius: 0.25rem;
      background: ${({ theme }) => theme.red};
      color: white;
      text-align: center;
      text-decoration: none;
    }
  }

  h4 {
    margin: 0 0 1rem;
    padding: 0.25em 1em;
    background: #222;
    color: white;
    text-align: center;
    font-size: 1rem;
  }
`

const InfoTableAdvanced = styled(InfoTableBasic)`
  grid-template:
    "tuition rentals" auto
    "changes rentals" auto / 1fr 1fr;
  grid-gap: 1rem;
  margin: 2rem auto;

  @media (max-width: 600px) {
    grid-template:
      "tuition" auto
      "changes" auto
      "rentals" auto / 1fr;
  }
`

const AdvancedLessonPrice = styled.div`
  table {
    width: 100%;
    max-width: 600px;
    margin: 3rem auto 4rem;
  }

  th,
  td {
    width: 50%;
    padding: 1em;
    border: 1px solid #222;
    text-align: center;
  }

  th {
    color: white;
    background: #222;
    font-weight: 500;
  }

  td {
  }
`
